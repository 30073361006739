<template>
  <router-view></router-view>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "apuracao-index",
  computed: {
    ...mapGetters({
      apuracao: "campanha/getApuracao"
    }),

    currentRouteName() {
      return this.$route.name;
    },

    infoRoute() {
      return "apuracao-campanha-empresa";
    }
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView(`${this.apuracao.title}`);
  }
};
</script>
